import React from 'react'
import {Box, Container} from '@mui/material'
import NavBar from 'components/NavBar'
import RoutesUser from 'components/RoutesUser'
import 'App.css'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Footer from 'components/Footer'

export default function App() {
  return (
    <>

      <NavBar />

      <Container maxWidth="xl">
        <Box sx={{
                  bgcolor: 'background.paper',
                  boxShadow: 2,
                  borderRadius: 2,
                  p: 3,
                  minWidth: 300,
                  mt:3
            }}>
            
              <RoutesUser />
            
          </Box>

        <Footer />

        <center style={{font:"12px Consolas"}}> Telegram group <a rel="noreferrer" href='https://t.me/tvcas' target='_blank'>@tvcas</a></center>
        </Container>
    </>
  )
}
