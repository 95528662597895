import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home'
import BlockDiagram from '../pages/BlockDiagram'
import Features from '../pages/Features'
import Install from '../pages/Install'
import License from '../pages/License'
import Interface from '../pages/Interface'
import Smartcards from '../pages/Smartcards'
import NotFound from '../pages/NotFound'
import Pairing from '../pages/Pairing'



export default function RoutesUser(props){

    return(
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/en' element={<Home />} />
            <Route path='/diagram' element={<BlockDiagram />} />
            <Route path='/features' element={<Features />} />
            <Route path='/install' element={<Install />} />
            <Route path='/interface' element={<Interface />} />
            <Route path='/smartcards' element={<Smartcards />} />
            <Route path='/pairing' element={<Pairing />} />
            <Route path='/license' element={<License />} />
            <Route path='/*' element={<NotFound />} />
        </Routes>
    );
}