import React, { useEffect } from 'react';

const GoogleTranslate = () => {
    useEffect(() => {
        const addGoogleTranslateScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                script.async = true;

                script.onload = () => {
                    resolve();
                };

                script.onerror = () => {
                    reject(new Error('Failed to load the Google Translate script.'));
                };

                document.body.appendChild(script);
            });
        };

        const initializeGoogleTranslate = () => {
            if (window.google && window.google.translate) {
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: 'en',
                        includedLanguages: 'en,ru,es,fr,de,ar,zh-CN,zh-TW,bg,ro,sl,sk,hu,it,pt,nl,pl,sr,tr,uz,mn,ka,hi,sq',
                    },
                    'google_translate_element'
                );
            } else {
                console.error('Google Translate object not found.');
            }
        };

        // Add the script and initialize after it loads
        if (!window.google || !window.google.translate) {
            addGoogleTranslateScript()
                .then(() => {
                    window.googleTranslateElementInit = initializeGoogleTranslate;
                    initializeGoogleTranslate();
                })
                .catch((err) => console.error(err.message));
        } else {
            initializeGoogleTranslate();
        }
    }, []);

    return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
