import React, { useEffect, useState } from 'react'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { Alert, AlertTitle, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import 'App.css'
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';

export default function Install() {

    const [server, setServer] = useState("")


    useEffect(function () {
        fetch("/ext.php?op=server_v")
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // or response.text() if expecting different data type
            })
            .then(data => {
                if (data.length > 20) data = "4.999"
                setServer("server_v." + data)
            })
            .catch(error => {
                // Error handling
                console.error('There was a problem with the fetch operation:', error);
            });
    }, [])

    const [openModal, setOpenModal] = useState(false)
    const [stepModal, setStepModal] = useState(0)
    const handleOpen = (p) => {
        setOpenModal(true)
        setStepModal(p.target.attributes.rel.value)
    };
    const handleClose = () => {
        setOpenModal(false);
    };



    function Item(props) {

        const img = "/sources/astra_step" + props.step + ".jpg"
        const alt = "Setting Astra Step " + props.step
        const h1 = "Step " + props.step

        return (
            <div style={{ border: "1px solid gray", padding: "1px" }}>
                <center><h3>{h1}</h3></center>
                <img src={img} alt={alt} rel={props.step} width="95%" onClick={handleOpen} style={{ cursor: "pointer" }} />
            </div>
        )
    }


    return (
        <>
            <h1><ElectricBoltIcon /> Server Installation</h1>

            <p>
                Installing the system is simple.
                The TVCAS4 server consists of a single executable file for Linux.
                In the archive, it is called <b>server_v.4.XXX</b>, but you can rename it as you wish.
                Download the archive <a href="https://tvcas.com/tvcas4.tar.gz">tvcas4.tar.gz</a>,
                unpack it to any convenient location on your disk, and run <b>server_v.4.XXX</b>.
            </p>

            <Alert>
                <AlertTitle>To install, type in the Linux terminal</AlertTitle>
                <code>
                    wget https://tvcas.com/tvcas4.tar.gz<br />
                    tar -xzvf tvcas4.tar.gz<br />
                    cd ./tvcas4
                    ./{server}
                </code>

                <AlertTitle>To update an already installed tvcas4</AlertTitle>
                <code>curl -sS https://tvcas.com/update.sh | bash</code>
            </Alert>

            <p>
                Do not try to run on older versions of Linux. In this case, there will almost certainly be a <b>GLIBC_2.XX</b> library error.
                If the operating system requirements are met (Debian 11 or later),
                after the first launch, a database <b>db.sqlite3</b> necessary for storing information will be created next to the executable file.
                When changing the TVCAS4 configuration (and on an internal timer every 24 hours), a backup of this database is made under the name <b>_db.sqlite3</b>.
                If you need direct administration of the database,
                we recommend the free cross-platform program <a href="https://sqlitebrowser.org" rel="noreferrer" target="_blank">SQLite browser</a>.
            </p>
            <p>
                By default, the TVCAS4 server runs on port <b>:8080</b>.
                The port number can be changed in the configuration section.
                As in previous versions, the system has two users:
            </p>
            <ul>
                <li>Superuser: <b>admin / admin</b> - all permissions</li>
                <li>Operator: <b>oper / oper</b> - limited permissions</li>
            </ul>
            <p>
                Also, after the first launch of the program, a <b>systemd</b> unit (file <b>/etc/systemd/system/tvcas4.service</b>) is created for server autostart.
                If you need the server to autostart, activate this unit.
            </p>
            <Alert>
                <AlertTitle>Autostart Unit Activation</AlertTitle>
                <code>
                    systemctl enable tvcas4<br />
                    systemctl start tvcas4
                </code>
            </Alert>

            <Alert severity="info" style={{ marginTop: "20px" }}>
                <AlertTitle>Process Management</AlertTitle>
                <code>
                    systemctl status tvcas4<br />
                    systemctl restart tvcas4<br />
                    systemctl stop tvcas4<br />
                    systemctl disable tvcas4
                </code>
            </Alert>

            <p>
                Note that to move the <b>tvcas4</b> files to another location on the disk later,
                first stop the process, move it, then <u>change the paths in the unit file</u>, refresh the systemd configuration with: <code>systemctl daemon-reload</code>,
                and only then reactivate the unit.
            </p>

            <Typography variant="h5" style={{ marginTop: "50px" }}><ElectricBoltIcon /> Initial Settings</Typography>

            <p>
                After logging into the system, go to the <ImageModal url="/sources/config.jpg">configuration section</ImageModal> and change system settings: passwords, ECM key, CAID codes, etc.
                Please note that after at least <span style={{ fontWeight: "bold", color: "red" }}>one programmed smart card</span> is present in the billing system, <span style={{ fontWeight: "bold", color: "red" }}>the ECM key cannot be changed</span>!
                Also, it is worth noting that unlike earlier versions, in TVCAS4 all keys are stored in encrypted form.
                Therefore, if you are migrating from TVCAS3, you need to convert the ECM key using the program <ImageModal url="/sources/converter.jpg">converter.exe</ImageModal>, available <a href="https://tvcas.com/converter.exe">here</a>. When importing a CSV file, the system will automatically recognize that it is from TVCAS3 and will handle this for you with the smart card EMM keys.
            </p>


            <h2><ElectricBoltIcon /> Cesbo Astra Scrambler Setup</h2>

            <p>
                During the development of the project, the <a href="https://cesbo.com" rel="noreferrer">Cesbo Astra</a> software was used as a scrambler.
                The setup of this scrambler boils down to creating a "CAS", and then the created CAS is assigned to services (TV channel streams).
                See the pictures below and everything will become clear.
            </p>


            <Grid container spacing={2}>
                <Grid item xs={4} >
                    <Item step='1' />
                </Grid>
                <Grid item xs={4} >
                    <Item step='2' />
                </Grid>
                <Grid item xs={4} >
                    <Item step='3' />
                </Grid>
            </Grid>

            <p>
                After completing the setup, you need to restart the scrambler, as the created EMM port opens only when Cesbo Astra is launched.
                If everything is done correctly, you will see connections in the generators.
            </p>

            <div align="center" style={{ marginBottom: "10px" }}><img src='/sources/ecmg_connect.jpg' alt='' /></div>
            <div align="center"><img src='/sources/emmg_connect.jpg' alt='' /></div>




            <Dialog
                open={openModal}
                onClose={handleClose}
                maxWidth={"lg"}
            >
                <DialogContent>
                    <img src={"/sources/astra_step" + stepModal + ".jpg"} alt='' width="100%" />
                </DialogContent>
            </Dialog>




            <Dalee url="interface" />

        </>
    )
}
