import { Box } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React, { useEffect, useState } from 'react'
import {isEn} from './Functions'


export default function Footer(){
    
    const [address, setAddress] = useState("Palestine")
    const [phone, setPhone] = useState("+972-599-500456")

    useEffect(function(){
        fetch("/ext.php?op=ip_code")
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.text(); // или response.text() если ожидается другой тип данных
        })
        .then(data => {
            if (data === "BY"){
                setAddress("пер.Старосельский, д.9")
                setPhone("+972-599-500456")
            }
        })
        .catch(error => {
            // Обработка ошибок
            console.error('There was a problem with the fetch operation:', error);
        });

        // Dynamically load Tawk.to script
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://embed.tawk.to/56e6ebcfe076c43a261ac9c7/default';
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        document.body.appendChild(script);
        return () => {
            // Cleanup script if component unmounts
            document.body.removeChild(script);
          };
        
    }, [])


    return (
        <Box>
            {(<div style={{margin:"20px 20px 0"}}>
                
                <div style={{display: "flex", justifyContent: "space-between"}} >
                    <span><b>Telegram</b>: <a href="https://t.me/introtik" target="_blank" rel="noreferrer">@introtik</a></span>
                    <span><b>Whatsapp</b>: <a href="https://wa.me/972599500456" target="_blank" rel="noreferrer">whatsapp</a></span>
                    <span><AccessTimeIcon style={{marginLeft:"20px", position:"relative", top:"5px"}} /> SAT-FRI from 10:00 to 20:00</span>
                </div>
            </div>)}
            <div style={{marginTop:"20px"}}> <nr/> </div>

            
                    </Box>
    )
}