import { Button } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React from "react";
import { useNavigate } from "react-router-dom";
import { isEn } from "./Functions";


export default function Dalee(props){
    const nav = useNavigate();
    var text = (props.text ?  props.text : "Next")
    return (
        <Button
            style={{marginTop:"20px"}}
            variant="contained"
            endIcon={<NavigateNextIcon />}
            onClick={()=> {
                if(props.url.indexOf("http")===0){
                    window.location.href=props.url
                }else{
                    nav("/" + (isEn() ? "en/" : "") +props.url)
                }
            }}>
                {text}
        </Button>
    )
}