import React, { useState } from 'react'
// import { Alert, AlertTitle } from '@mui/material';
import 'App.css'
import { Alert, AlertTitle, Button, FormControlLabel, Grid } from "@mui/material";
import PaymentIcon from '@mui/icons-material/Payment';
import { Link } from 'react-router-dom';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export default function License(){ 

    const [uid, setUid] = useState("")
    const [email, setEmail] = useState("")
    const [promoVisible, setPromoVisible] = useState(false)
    const [promoText, setPromoText] = useState("")


  

    function isEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    return (
        <>
          <h1>Licensing Terms</h1>

          <p>
            The TVCAS4 software (the Software), designed for encoding TV channels in a TV operator's network, is supplied "as is."
            You can test the Software for as long as you need,
            and the author guarantees that its functionality will not change over time.
            It is also legally guaranteed that no personal data (keys, subscriber data, etc.) is sent from the Software to external servers.
            However, the author is not responsible if using the Software causes any material or reputational damage
            due to non-compliance with <Link to="/features">recommendations</Link> and security conditions.
          </p>

          <p>
            <b>Restrictions (without purchasing a license):</b>
          </p>
          <ul>
              <li>smartcard erasure function and disabling the request counter on it are unavailable</li>
              <li>setting your own <code>trademark</code> on flashed cards is unavailable (otherwise it will be 'TVCAS')</li>
              <li>license wait time from 20 to 30 seconds when flashing smartcards in <b>uploader.exe</b></li>
              <li>EMM generator speed controller not available</li>
              <li>the smartcard radar function is unavailable</li>
              <li>popup message for subscribers: "<i style={{color:"green", fontWeight:"bold"}}>Your operator uses a free version of TVCAS4</i>"</li>
              <li>virtual smartcards for AMD64 and ARM64 platforms are not available for download</li>
          </ul>

          <p>
            To remove these restrictions, you need to purchase a license,
            the so-called <b>Activation ID (AID)</b>.
            The license is generated using <b>UID</b> number specified in the Config page.
          </p>

          <center><img style={{margin:"30px 0", border:"1px solid #eee", boxShadow:"0px -5px 10px 0px rgba(0, 0, 0, 0.5)"}} src="/sources/aid.jpg" alt="uid-aid" width="80%" /></center>

          <p>
            The cost of a license for one UID is <b>$399.00</b>.
            The license for your UID will be sent to the provided EMAIL immediately after payment confirmation.
            License is a lifetime license and cannot be refunded. It also covers all future updates and bug fixes for version 4.x. 
            However, to upgrade to future version 5.x, you must purchase a new license.
            If you lose your license or for some reason your UID changes after a server upgrade/change,
            please contact us via email at <b>admin@tvcas.com</b>.
            We will try to assist you promptly.
          </p>

          <hr />

          <Grid container>


          <Grid item xs={4}>
            <Alert style={{marginBottom:"20px"}}>
            We accept USDT (Tether) cryptocurrency payments.
            </Alert>
          </Grid>


            <Grid item xs={4}>

          <center>


            <b>To obtain a license, fill in the required fields and pay for the order</b>
            <div>
            <a href="https://nowpayments.io/payment/?iid=5356069203" target="_blank" rel="noreferrer noopener">
              <img src="https://nowpayments.io/images/embeds/payment-button-white.svg" alt="Cryptocurrency & Bitcoin payment button by NOWPayments"></img>
            </a>
            </div>
           
          </center>

          </Grid>

          <Grid item xs={4}>
            <Alert severity={"warning"}>
              <AlertTitle>Refund Policy</AlertTitle>
              

              <div style={{marginTop:"5px"}}>Please perform all necessary tests before purchasing. We do not offer refunds for TVCAS, as it is completely free. Once payment is made, no refunds will be issued.</div>
            </Alert>
          </Grid>

          </Grid>
        </>
    )
}
