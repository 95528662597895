import React, { useState } from 'react'
import { Alert, Button, Grid, Link } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Dalee from 'components/DaleeBtn';
import ImageModal from 'components/ImageModal';
import { useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { NavLink } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function Home() {

    const [verDate, setVerDate] = useState("null")

    useEffect(function(){
        fetch("/ext.php?op=verDate")
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.text(); // or response.text() if another data type is expected
        })
        .then(data => {
            if(data.length > 25) data = "null"
            setVerDate(data)
        })
        .catch(error => {
            // Error handling
            console.error('There was a problem with the fetch operation:', error);
        });
    }, [])

    return (
        <>
            <h1>
                <Grid container>
                    <Grid item xs={6}>
                        <ElectricBoltIcon />Current version: TVCAS v{verDate}
                    </Grid>
                    <Grid item xs={6} style={{textAlign: "right", paddingRight:"10px"}}>
                        <NavLink to="/tvcas4.tar.gz" className="Navlink" target="_blank" rel="noopener noreferrer" style={{paddingRight:10}}>
                            <Button variant='contained' color="error"><CloudDownloadIcon /> <span style={{paddingLeft:"10px"}}>Download</span></Button>
                        </NavLink>
                        <NavLink to="http://demo.tvcas.com" className="Navlink" target="_blank" rel="noopener noreferrer" style={{paddingRight:10}}>
                            <Button variant='contained' color="success"><OpenInNewIcon /> <span style={{paddingLeft:"10px"}}>Demo</span></Button>
                        </NavLink>
                        
                    </Grid>
                </Grid>
            </h1>

            <Grid container mb={3} mt={5}>
                <Grid item xs={4}>

                    <div className="image-3d-container">
                        <img src='/sources/ui-smartcards.jpg' alt='TVCAS4 dashboard' className="image-3d" />
                    </div>

                </Grid>
                <Grid item xs={8}> 
                    <p>
                        TVCAS4 is a Conditional Access System (CAS) designed to restrict access to TV channels in terrestrial, cable, and satellite broadcasting networks.
                        The project follows a unified encryption algorithm <Link rel="noreferrer" href='https://www.etsi.org/deliver/etsi_TS/103100_103199/103197/01.05.01_60/ts_103197v010501p.pdf' target='_blank'>SimulCrypt</Link>.
                        TVCAS4 works successfully with widely-used <ImageModal url="/sources/cam_modules.jpg">CAM modules</ImageModal> and also without them.
                        This project aims to create a universal content protection product that caters to the broad needs of operators.
                        The encoding process in TVCAS4 has its own cryptosystem based on Triple DES (128-bit).
                        Also, recently we added the ability for the system to work <b>without smartcards</b>, through so-called "virtual CAM" and "virtual smart card" using the <code>newcamd525</code> protocol.
                    </p>
                    <p>
                        The communication speed between the smart card and the CAM module should not differ from 9600 baud.
                        TVCAS4 is also friendly to receivers, i.e., set-top boxes (STB), where conditional access is implemented via software using OSCam, CCcam, Wicard, etc.
                    </p>

                </Grid>
            </Grid>

            <h3>Main differences from TVCAS3</h3>
            <div style={{padding: "10px 20px"}}>🔹 The project is written in <a href="https://go.dev" target="_blank" rel="noreferrer">Golang</a> (backend) using <a href="https://react.dev" target="_blank" rel="noreferrer">ReactJS</a> + <a href="https://mui.com" target="_blank" rel="noreferrer">MaterialUI</a> (frontend) technologies.
                
                The TVCAS4 server is a standalone executable that does not require any dependency libraries.
                You just need to run it on a Linux system (Debian 11 and newer) and it’s ready to go.
            </div>
            <div style={{padding: "10px 20px"}}>🔹 Added support for new cards THC20F150A. No additional action is required from you. You work with F17BD and F150A cards as if they were the same. TVCAS4 solves compatibility issues for you.
            </div>
            <Alert severity="info" style={{padding: "10px 20px"}}><b>Be careful!</b> F150A cards can be flashed using the old uploader from TVCAS3, but neither a <ImageModal url="/sources/eraser.jpg">physical eraser</ImageModal> nor the software from TVCAS4 will be able to return them to a BLANK state. After such an erasing attempt, it turns into a "brick".
            </Alert>
            <div style={{padding: "10px 20px"}}>🔹 Ability to work without EMM. Subscription information is transmitted in ECM (so-called <b>ecmgift</b> function) — the downside is that it’s slower, the upside is that no card can bypass its actual subscription.
            </div>
            <div style={{padding: "10px 20px"}}>🔹 Support for CAM modules Irdeto SmarDTV P/N 904505 R1.3. Caid: 0602 (RUS).
            </div>
            <div style={{padding: "10px 20px"}}>🔹 If the card receives more than 65535 ECMs and not a single EMM (or <b>ecmgift</b>), it gets blocked to counteract disabling EMM by the subscriber. The smart card will start opening channels as soon as the first EMM (or <b>ecmgift</b>) arrives.
            </div>
            <div style={{padding: "10px 20px"}}>🔹 Real <NavLink to="/pairing">pairing</NavLink> using its own protocol.
            </div>
            <div style={{padding: "10px 20px"}}>🔹 Smooth migration from TVCAS3 to the new TVCAS4 version via export/import files. Cards do not necessarily need to be changed, but new features will not work on old-flashed cards. To convert an ECM key, use <a href="/converter.exe">converter.exe</a> in the archive.
            </div>
            <div style={{padding: "10px 20px"}}>🔹 The request counter can now be disabled and the card can be returned to BLANK via software (this function is available after purchasing a license).
            </div>

            <Dalee url="features" />
        </>
    )
}
