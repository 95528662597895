import React from 'react'
import { Grid } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import 'App.css'
import Dalee from 'components/DaleeBtn';

export default function BlockDiagram() {
    return (
        <>
            <h1><ElectricBoltIcon /> System Operation Principle</h1>
            <p>
                Below is the operation diagram of TVCAS4 in conjunction with the <a href="https://cesbo.com" target="_blank" rel="noreferrer">Cesbo Astra</a> scrambler.
                In the simplest case, two connections are established between ECMG / EMMG and the Astra scrambler.
                Arrows indicate the actual initiator of the connection. Thus, the left arrow "connect 1" indicates that the scrambler connects to ECMG,
                while the right arrow "connect 2" indicates the opposite, that EMMG connects to the scrambler port.
            </p>

            <center><img src='/sources/block_diagram_tvcas.svg' alt='tvcas block diagram' width="60%" /></center>

            <p>To transmit the ECM packet, a successful establishment of <b>connection 1</b> is required. The scrambler generates keys CW1 and CW2, sends them to the ECMG generator,
            which then forms the ECM packet, which is subsequently encapsulated into the stream with the PID specified in the scrambler.
            This encrypted ECM packet contains four data fields: <b>current time</b> [4 bytes], <b>keys CW1 and CW2</b> (8+8 bytes), <b>access criteria</b> (4 bytes),
            and <b>ecmgift</b> (48 bytes). The ECM packet is intended for all smart cards.</p>

            <p>EMMG generates packets for each <u>programmed</u> smart card that exists in the database and sends them to the scrambler via <b>connection 2</b>.
            Thus, each EMM packet is intended only for a specific smart card. The sending rate of EMM is about 150 per minute. Furthermore, if
            the information on the smart card is updated via the interface or API, the EMM packet will be sent to the scrambler immediately.</p>

            <Grid container spacing={2}>
                <Grid item xs={6}><center><img src='/sources/ecm_packet_tvcas.jpg' alt='ECM-packet' style={{maxWidth:"550px"}} /><br />TVCAS ECM Packet</center></Grid>
                <Grid item xs={6}><center><img src='/sources/emm_packet_tvcas.jpg' alt='EMM-packet' style={{maxWidth:"550px"}} /><br />TVCAS EMM Packet</center></Grid>
            </Grid>

            <p>
                The data in the packets are encrypted using an algorithm similar to <a href='https://en.wikipedia.org/wiki/Triple_DES' target='_blank' rel="noreferrer" >Triple DES</a> (3DES-ECB).
                It uses a <a href='https://youtu.be/mE_s-R5wvpw?t=2534' target='_blank' rel="noreferrer" >Feistel network</a> and multi-round bit shifting. The key is the only secret here.
                Without it, decrypting the packet is impossible. The keys are stored in the TVCAS server's database and on programmed smart cards.
                If the smart cards are secure – with safeguards against reading code and memory – the server is much more complex – ensure the security of the keys,
                starting with personnel, and using only a local machine without Internet access for this purpose!
            </p>

            <Dalee url="install" />

        </>
    )
}
